.modal-backdrop {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  .modal {
    z-index: 9999;
    position: fixed;
    top: 10%;
    left: 10%;
    width: calc(80% - 30px);
    margin: 0 auto;
    background-color: $white;
    border: 1px solid $light_blue;
    border-radius: 10px;
    padding: 15px;
    max-height: 80%;
    overflow: scroll;
    overscroll-behavior: contain;

    .close-btn {
      position: fixed;
      right: 8%;
      top: 7%;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      background-color: $dark_blue;
      color: $light_blue;
    }

    .modal-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}

.modal-wizard {
  max-width: 500px;
  min-height: 300px;

  h3 {
    .info-icon {
      margin-right: 15px;
    }

    .info-icon-text {
      font-weight: normal;
      text-align: left;
      font-size: 16px;
    }
  }

  &.modal {
    left: calc(50% - 250px);
  }

  input {
    width: 80%;
    max-width: 400px;
  }

  button {
    margin-top: 50px;
  }

  .wizard-logo {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .wizard-logo-img {
      width: 30px;
      padding: 10px;
    }
    .wizard-logo-text {
      font-family: $font_title;
      color: $dark_blue;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.modal-publish {
  .modal-content {
    padding: 20px;
    padding-top: 10px;
    h3 {
      margin-bottom: 40px;
    }

    .label-row {
      label {
        width: 200px;
      }
    }

    .btn-bar {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: $mobile_size) {
  .modal-backdrop {
    .modal {
      top: 10%;
      left: 10%;
      width: calc(80% - 40px);
      padding: 20px;

      .close-btn {
        right: 13%;
        top: 5%;
      }

      &.modal-wizard {
        input {
          width: calc(100% - 20px);
        }
      }

      &.full-mobile {
        position: absolute;
        z-index: 999999;
        border: none;
        top: 0px;
        left: 0px;
        border-radius: 0px;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        max-height: none;

        .close-btn {
          right: 5px;
          top: 5px;
        }
      }
    }
  }
}
