input,
select {
  width: max-content;
  height: 30px;
  padding: 3px 15px;
  border-radius: 10px;
  outline: none;
  border: 1px solid $dark_blue;
  font-size: 16px;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $gray;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $gray;
  }

  &[type="checkbox"] {
    width: 40px !important;
  }
}

button,
.button {
  color: $dark_blue;
  background-color: $light_blue;
  border: none;
  font-family: $font_title;
  padding: 5px 15px;
  border-radius: 10px;
  height: 40px;
  width: fit-content;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &.big {
    font-weight: bold;
    font-size: 1rem;
  }

  &.dark {
    color: $light_blue;
    background-color: $dark_blue;
  }

  &.link {
    color: $dark_blue;
    background-color: transparent;
  }
}

.meta {
  color: $gray;
}

.dropdown {
  .button {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-left: 15px;

    &.open {
      border-radius: 10px 10px 0px 0px;
    }

    & > div {
      padding: 0px;
      height: 20px;
      margin-right: 10px;
      font-family: Sansation;
      font-size: 15px;
      display: flex;
    }
  }
  .menu {
    padding: 10px;
    background-color: $light_gray;
    border-radius: 0px 0px 10px 10px;
    position: absolute;

    & > div {
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dropdown-arrow {
    border-left: 1px solid;
    padding-left: 7px !important;
    height: auto !important;

    &:hover {
      opacity: 0.7;
    }
  }
}

.label-row {
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;

  label {
    width: 300px;
    display: inline-block;
    margin-bottom: 5px;
  }
  input,
  select {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: $mobile_size) {
  .label-row {
    box-sizing: border-box;

    label {
      width: fit-content;
      display: block;
    }

    input,
    select {
      display: block;
      max-width: none;
      box-sizing: border-box;
      height: 40px;
    }

    .alert-error {
      margin-top: -10px;
    }
  }
}

.info-icon-container {
  display: inline-block;
  cursor: pointer;

  .info-icon {
    width: 18px;
    margin-left: 10px;
  }

  .info-icon-text {
    display: none;
    position: absolute;
    background-color: $light_gray;
    border-radius: 5px;
    padding: 5px 10px;
    max-width: 300px;
    color: $black;
    margin-left: 35px;
    margin-top: -35px;
    white-space: pre-line;
  }

  &:hover,
  &:active {
    .info-icon-text {
      display: block;
    }
  }
}

.badge-elaborating {
  background-color: $gray;
  color: $dark_blue;
  border-radius: 10px;
  padding: 5px 10px;
  margin-top: 4px;
  margin-left: 15px;
  width: fit-content;
  display: inline-block;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.alert-success {
  background-color: $green;
  border-radius: 10px;
  padding: 5px 10px;
  color: $dark_blue;
  margin-bottom: 20px;
  width: fit-content;
}

.alert-error {
  background-color: $red;
  color: $white;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 20px;
  width: fit-content;
}

.message,
.error {
  color: $dark_blue;
}
